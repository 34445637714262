<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?'电梯档案':'电梯档案'"
    width="900px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="formFillWidth"
      :label-width="$l('elevator.labelWidth','110px')">
      <el-tabs v-model="tabActive" type="card">
        <el-tab-pane :label="$l('common.basicInfo','基础信息')" name="first">
          <el-tag class="tag">品牌型号</el-tag><el-button size="small" type="text" @click="$refs.modalSelect.open()">选择型号</el-button>
          <div class="vm-separate content">
            <div>制造商:{{model.model.manufacturerName}}</div>
            <div>品牌:{{model.model.brandName}}</div>
            <div>型号:{{model.model.modelName}}</div>
          </div>
          <div class="vm-separate content">
            <div>电梯类别:{{model.model.categoryDesc}}</div>
            <div>电梯品种:{{model.model.varietyDesc}}</div>
            <div>控制系统:{{model.model.controlSystemDesc}}</div>
          </div>
          <el-tag class="tag">出厂参数</el-tag><el-button v-if="!factoryEdit" size="small" type="text" @click="factoryEdit=true">编辑</el-button>
          <el-button v-if="factoryEdit" size="small" type="text" @click="factorySubmit">保存</el-button><el-button v-if="factoryEdit" size="small" type="text" @click="factoryEdit=false">取消</el-button>
          <div class="vm-separate content">
            <div>出厂编号:<el-input v-if="factoryEdit" v-model="model.factory.factoryCode" style="width: 200px"></el-input> <span v-if="!factoryEdit">{{model.factory.factoryCode}}</span></div>
            <div>出厂日期:<el-date-picker v-if="factoryEdit" v-model="model.factory.factoryDate" style="width: 200px"></el-date-picker> <span v-if="!factoryEdit">{{model.factory.factoryDate}}</span></div>
            <div>控制系统:<vm-dict-select v-if="factoryEdit" v-model="model.factory.controlSystem" type="elevatorControlSystem" style="width: 200px"></vm-dict-select> <span v-if="!factoryEdit">{{model.factory.controlSystemDesc}}</span></div>
          </div>
          <div class="vm-separate content">
            <div>额定速度:<el-input v-if="factoryEdit" v-model="model.factory.ratedSpeed" style="width: 200px"></el-input> <span v-if="!factoryEdit">{{model.factory.ratedSpeed}}</span></div>
            <div>额定载重:<el-input v-if="factoryEdit" v-model="model.factory.ratedLoads" style="width: 200px"></el-input> <span v-if="!factoryEdit">{{model.factory.ratedLoads}}</span></div>
            <div>提升高度:<el-input v-if="factoryEdit" v-model="model.factory.liftingHeight" style="width: 200px"></el-input> <span v-if="!factoryEdit">{{model.factory.liftingHeight}}</span></div>
          </div>
          <div class="vm-separate content">
            <div>层数:<el-input v-if="factoryEdit" v-model="model.factory.totalFloor" style="width: 228px"></el-input> <span v-if="!factoryEdit">{{model.factory.totalFloor}}</span></div>
            <div>站数:<el-input v-if="factoryEdit" v-model="model.factory.totalStation" style="width: 228px"></el-input> <span v-if="!factoryEdit">{{model.factory.totalStation}}</span></div>
            <div>门数:<el-input v-if="factoryEdit" v-model="model.factory.totalDoor" style="width: 228px"></el-input> <span v-if="!factoryEdit">{{model.factory.totalDoor}}</span></div>
          </div>
          <div class="vm-separate content">
            <div>
              开门方式: <vm-dict-select v-if="factoryEdit" v-model="model.factory.doorOpenWay" style="width: 200px" type="elevatorDoorOpenWay"></vm-dict-select>
              <span v-if="!factoryEdit">{{model.factory.doorOpenWayDesc}}</span>
            </div>
            <div>开门尺寸:<el-input v-if="factoryEdit" v-model="model.factory.doorWidth" style="width: 200px"></el-input> <span v-if="!factoryEdit">{{model.factory.doorWidth}}</span></div>

            <div></div>
          </div>
          <div class="vm-separate content">
            <div>顶层高度:<el-input v-if="factoryEdit" v-model="model.factory.topHeight" style="width: 200px"></el-input> <span v-if="!factoryEdit">{{model.factory.topHeight}}</span></div>
            <div>底坑高度:<el-input v-if="factoryEdit" v-model="model.factory.pitHeight" style="width: 200px"></el-input> <span v-if="!factoryEdit">{{model.factory.pitHeight}}</span></div>
            <div>
              是否有机房:<el-select v-if="factoryEdit" v-model="model.factory.machineRoom" style="width: 180px">
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
              </el-select> <span v-if="!factoryEdit">{{model.factory.machineRoom?'是':'否'}}</span>
            </div>
          </div>
          <div class="vm-separate content">
            <div>
              地上楼层计数:<el-input-number
                v-if="factoryEdit"
                v-model="aboveGroundFloorCount"
                controls-position="right"
                :min="0"
                :precision="0"
                style="width: 172px"
                @change="updateFloors"
              ></el-input-number>
              <span v-if="!factoryEdit">{{aboveGroundFloorCount}}</span>
            </div>
            <div>
              地下楼层计数:<el-input-number
                v-if="factoryEdit"
                v-model="underGroundFloorCount"
                controls-position="right"
                :min="-2"
                :max="0"
                :precision="0"
                style="width: 172px"
                @change="updateFloors"
              ></el-input-number>
              <span v-if="!factoryEdit">{{underGroundFloorCount}}</span>
            </div>

            <div></div>
          </div>
          <div class="vm-separate content">
            <el-table :data="model.factory.floors" label-position="left" class="demo-table-expand">
              <el-table-column prop="name" label="物理楼层" align="center" width="170">
              </el-table-column>

              <el-table-column prop="displayName" label="标识楼层" align="center">
                <template slot-scope="scope">
                  <el-input-number
                    v-if="factoryEdit"
                    v-model="scope.row.displayName"
                    controls-position="right"
                    :precision="0"
                    style="width: 100%"></el-input-number>
                  <span v-if="!factoryEdit">{{scope.row.displayName}}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <el-tag class="tag">注册登记</el-tag><el-button v-if="!registrationEdit" size="small" type="text" @click="registrationEdit=true">编辑</el-button>
          <el-button v-if="registrationEdit" size="small" type="text" @click="registrationSubmit">保存</el-button><el-button v-if="registrationEdit" size="small" type="text" @click="registrationEdit=false">取消</el-button>

          <div class="vm-separate content">
            <div>使用单位:{{model.registration.usingCompanyName}}</div>
            <div>楼盘:{{model.registration.realEstateName}}</div>
            <div>楼宇:<vm-select-input v-if="registrationEdit" style="width: 200px" :value="model.registration.buildingName" @select="$refs.buildingSelect.open()"></vm-select-input> <span v-if="!registrationEdit">{{model.registration.buildingName}}</span></div>
          </div>
          <div class="vm-separate content">
            <div>内部编号:<el-input v-if="registrationEdit" v-model="model.registration.elevatorName" style="width: 200px"></el-input> <span v-if="!registrationEdit">{{model.registration.elevatorName}}</span></div>
            <div>
              用途分类:        <vm-dict-select v-if="registrationEdit" v-model="model.registration.useType" style="width: 200px" type="elevatorUseType"></vm-dict-select>
              <span v-if="!registrationEdit">{{model.registration.useTypeDesc}}</span>
            </div>

            <div></div>
          </div>
          <div class="vm-separate content">
            <div>注册代码:<el-input v-if="registrationEdit" v-model="model.registration.regCode" style="width: 200px"></el-input> <span v-if="!registrationEdit">{{model.registration.regCode}}</span></div>
            <div>注册日期:<el-date-picker v-if="registrationEdit" v-model="model.registration.regDate" value-format="yyyy-MM-dd 00:00:00" style="width: 200px"></el-date-picker> <span v-if="!registrationEdit">{{model.registration.regDate}}</span></div>

            <div></div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="遥监信息" name="second">
          <el-tag class="tag">设备信息</el-tag><el-button size="small" type="text" @click="$refs.deviceSelect.open(id)">选择设备</el-button>
          <div class="vm-separate content">
            <div>设备型号:{{device.iotDeviceModelDesc}}</div>
            <div>设备编号:{{device.iotDeviceName}}</div>
            <div>端口:{{device.iotDevicePort}}</div>
          </div>
          <div class="vm-separate content">
            <div>是否在线:{{device.online?"是":"否"}}</div>
            <div>最近心跳时间:{{device.lastHeartbeatTime}}</div>
            <div></div>
          </div>
          <el-tag class="tag">电梯每日统计</el-tag>
          <vm-table
            ref="vmTable"
            height="200"
            :style="{marginTop:'5px',marginLeft:'15px'}"
            :immediately="true"
            :filter="{elevatorId:id,orderColumnName:'timestamp'}"
            url="elevator/iot/daily-statistics/page"
            :border="true"
            :data="dailyStatistics">
            <el-table-column prop="timestamp" label="时间" align="center"></el-table-column>
            <el-table-column prop="totalRunningTime" label="累计运行时间" align="center"></el-table-column>
            <el-table-column prop="totalRunningDistance" label="累计运行距离" align="center"></el-table-column>
            <el-table-column prop="totalRunningCount" label="累计启动次数" align="center"></el-table-column>
            <el-table-column prop="totalDoorOpeningCount" label="累计开门次数" align="center"></el-table-column>
          </vm-table>
          <el-tag class="tag">电梯故障统计</el-tag>
          <vm-table
            ref="statistics"
            height="200"
            :style="{marginTop:'5px',marginLeft:'15px'}"
            :immediately="true"
            :filter="{elevatorId:id}"
            url="elevator/iot/fault-statistics/page">
            <el-table-column prop="lastOccurredTime" label="最近发生时间" align="center"></el-table-column>
            <el-table-column prop="firstOccurredTime" label="首次发生时间" align="center"></el-table-column>
            <el-table-column prop="modelDesc" label="故障集" align="center"></el-table-column>
            <el-table-column prop="faultCode" label="故障代码" align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="goToBasicFault(scope.row)">{{scope.row.faultCode}}</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="solution" label="解决方案" align="center"></el-table-column>
            <el-table-column prop="orderProposal" label="工单处理对策" align="center"></el-table-column>
            <el-table-column prop="lastRemovedTime" label="最近解除时间" align="center"></el-table-column>
          </vm-table>
        </el-tab-pane>

        <el-tab-pane :label="$l('wb.worker','维修保养')" name="four">
          <el-tag class="tag">维修历史</el-tag>
          <vm-table
            ref="history"
            :style="{marginTop:'5px',marginLeft:'15px'}"
            :immediately="true"
            :filter="{elevatorId:id,completed:1,orderColumnName:'completedTime',asc:0}"
            height="200"
            url="maintenance/repair-orders">
            <el-table-column prop="teamName" label="维保班组" align="center"></el-table-column>
            <el-table-column prop="assigneeName" label="维修人员" align="center"></el-table-column>
            <el-table-column prop="orderCode" label="工单编号" align="center"></el-table-column>
            <el-table-column prop="typeDesc" label="维修类型" align="center"></el-table-column>
            <el-table-column prop="completedTime" label="维修完成时间" align="center"></el-table-column>
            <el-table-column prop="faultPhenomenon" label="故障现象" align="center"></el-table-column>
            <el-table-column prop="faultReason" label="故障原因" align="center"></el-table-column>
            <el-table-column prop="repairResult" label="处理结果" align="center"></el-table-column>
          </vm-table>

          <el-tag class="tag">保养历史</el-tag>
          <vm-table
            ref="maintenance"
            :immediately="true"
            :style="{marginTop:'5px',marginLeft:'15px'}"
            height="200"
            :filter="{elevatorId:id,completed:1,orderColumnName:'completedTime',asc:0}"
            url="maintenance/maintain-order/page">
            <el-table-column prop="teamName" label="维保班组" align="center"></el-table-column>
            <el-table-column prop="assigneeName" label="保养人员" align="center"></el-table-column>
            <el-table-column prop="orderCode" label="工单编号" align="center"></el-table-column>
            <el-table-column prop="typeDesc" label="保养类型" align="center"></el-table-column>
            <el-table-column prop="completedTime" label="保养完成时间" align="center"></el-table-column>
            <el-table-column prop="totalRunningTime" label="累计运行时间" align="center"></el-table-column>
            <el-table-column prop="totalRunningDistance" label="累计运行距离" align="center"></el-table-column>
            <el-table-column prop="totalRunningCount" label="累计启动次数" align="center"></el-table-column>
            <el-table-column prop="totalDoorOpeningCount" label="累计开门次数" align="center"></el-table-column>
          </vm-table>

          <div class="vm-separate content">
            <div>下次计划保养日期:{{maintainPlan.planDate?$moment(maintainPlan.planDate).format('YYYY-MM-DD'):""}}</div>
            <div>下次保养类型:{{maintainPlan.typeDesc}}</div>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$l('wb.wbInfo','限速器校验和制动试验')" name="five">
          <el-tag class="tag">限速器校验历史</el-tag>
          <el-table :border="true">
            <el-table-column prop="name" label="校验人员" align="center"></el-table-column>
            <el-table-column prop="iotProtocolSourceDesc" label="校验日期" align="center"></el-table-column>
          </el-table>
          <div class="vm-separate content">
            <div>下次计划校验日期:</div>
            <div></div>
          </div>
          <el-tag class="tag">制动试验历史</el-tag>
          <el-table :border="true">
            <el-table-column prop="name" label="试验人员" align="center"></el-table-column>
            <el-table-column prop="iotProtocolSourceDesc" label="实验日期" align="center"></el-table-column>
          </el-table>
          <div class="vm-separate content">
            <div>下次计划试验日期:</div>
            <div></div>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$l('wb.wbInfo','检验检测')" name="six">
          <el-tag class="tag">校验历史</el-tag>
          <el-table :border="true">
            <el-table-column prop="name" label="检验机构" align="center"></el-table-column>
            <el-table-column prop="iotProtocolSourceDesc" label="检验人员" align="center"></el-table-column>
            <el-table-column prop="iotProtocolSourceDesc" label="检验类型" align="center"></el-table-column>
            <el-table-column prop="iotProtocolSourceDesc" label="类型" align="center"></el-table-column>
          </el-table>
          <div class="vm-separate content">
            <div>下次计划检验日期:</div>
            <div></div>
          </div>
          <el-tag class="tag">检测历史</el-tag>
          <el-table :border="true">
            <el-table-column prop="name" label="检测机构" align="center"></el-table-column>
            <el-table-column prop="iotProtocolSourceDesc" label="检测人员" align="center"></el-table-column>
            <el-table-column prop="iotProtocolSourceDesc" label="检测类型" align="center"></el-table-column>
            <el-table-column prop="iotProtocolSourceDesc" label="检测日期" align="center"></el-table-column>
          </el-table>
          <div class="vm-separate content">
            <div>下次计划检测日期:</div>
            <div></div>
          </div>
        </el-tab-pane>
        <el-tab-pane v-if="$auth('半月运行报告')" :label="$l('wb.wbInfo','半月运行报告')" name="seven">
          <el-table v-loading="reportLoading" :data="reportList" :border="true">
            <el-table-column prop="createTime" label="生成日期" align="center">
              <template slot-scope="scope">
                <span>{{$moment(scope.row.createTime).format('YYYY-MM-DD')}}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate">
              <template slot-scope="scope">
                <el-button type="primary" @click="$refs.reportDetail.openDeatail(scope.row.id)">{{$l("common.detail", "查看")}}</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <span slot="footer">
      <el-button @click="goback">{{$l("common.goback", "返回")}}</el-button>
      <!--      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save", "保存")}}</el-button>-->
    </span>
    <company-select ref="manufacturingSelect" @select="manufacturingSelect"></company-select>
    <project-select ref="projectSelect" @select="projectSelect"></project-select>
    <device-select ref="deviceSelect" @select="deviceSelect"></device-select>
    <user-select ref="userSelectM1" @select="selectWorker(1,$event)"></user-select>
    <user-select ref="userSelectM2" @select="selectWorker(2,$event)"></user-select>
    <event-template-select ref="faultTemplateSelect" @select="faultTemplateSelect"></event-template-select>
    <event-template-select ref="eventTemplateSelect" @select="eventTemplateSelect"></event-template-select>
    <modal-select ref="modalSelect" @select="modalSelect"></modal-select>
    <building-select ref="buildingSelect" @select="buildingSelect"></building-select>
    <fault-detail ref="faultDetail" @select="buildingSelect"></fault-detail>
    <report-detail ref="reportDetail"></report-detail>
  </el-dialog>
</template>

<script>
  import UserSelect from "@/views/user/UserSelect";
  import CompanySelect from "@/views/company/Select";
  import ProjectSelect from "@/common/select/ProjectSelect";
  import EventTemplateSelect from "@/views/eventTemplate/Select";
  import ModalSelect from "@/common/select/ModalSelect";
  import BuildingSelect from "@/common/select/BuildingSelect";
  import DeviceSelect from "@/common/select/DeviceSelect";
  import FaultDetail from "@/views/basic/FaultDetail";
  import ReportDetail from "@/views/elevator/ReportDetail";

  export default {
    components: {UserSelect, CompanySelect, ProjectSelect, DeviceSelect, EventTemplateSelect,ModalSelect,BuildingSelect,FaultDetail,ReportDetail},
    data() {
      return {
        id:"",
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        reportLoading:false,
        tabActive: "first",
        floorStart: 0,
        floorEnd: 0,
        factoryEdit:false,
        registrationEdit:false,
        aboveGroundFloorCount: 0,
        underGroundFloorCount: 0,
        model: {
          factory:{},
          model:{},
          registration:{},
        },
        device:{

        },
        dailyStatistics:[],
        faultStatistics:{},
        repairOrders:[],
        maintainOrders:[],
        maintenanceProject:{},
        maintainPlan:{},
        memberList:[],
        reportList:[],
      };
    },
    mounted() {
      let elevatorId = this.$route.query.elevatorId;
      this.getData(elevatorId);
    },
    methods: {
      open(id) {
        this.model.id = id;
        this.id= id;
        this.dialogVisible = true;
        this.getData();
        const time= window.setTimeout(()=>{
          this.$refs.vmTable.getList(1);
          this.$refs.history.getList(1);
          this.$refs.statistics.getList(1);
          this.$refs.maintenance.getList(1);
          this.getReportList();
          window.clearTimeout(time);
        },100);

      },
      async getData() {
        if (this.model.id) {
          // this.contentLoading = true;

          this.$http.get(`elevator/base/${this.model.id}`).then(data => {
            this.contentLoading = false;
            this.model = data;
            if (!data.model) {
              this.model={...this.model,model: {}};
            }
            if (!data.factory) {
              this.model.factory={};
            }
            if (data.factory.floors.length > 0) {
              let arr = [];
              this.model.factory.floors.forEach(function(item) {
                arr.push(item.displayName);
              });
              this.aboveGroundFloorCount = Math.max.apply(null,arr);
              this.underGroundFloorCount = Math.min.apply(null,arr);
              if (this.underGroundFloorCount > 0) {
                this.underGroundFloorCount = 0;
              }
            }
            if (!data.registration) {
              this.model.registration={};
            }
          });
          this.$http.get(`elevator/iot/${this.id}`).then(data => {
            this.contentLoading = false;
            this.device = data;
          });


          //
          // this.$http.get("project/maintenance-project",{elevatorId:this.model.id}).then(data => {
          //   this.contentLoading = false;
          //   this.maintenanceProject = data;
          //   this.teamId= data.teamId;
          //
          // });
          // window.setTimeout(()=>{
          //   this.$http.get("organization/team/member/page",{teamId:this.teamId}).then(res => {
          //     if (this.teamId) {
          //       this.contentLoading = false;
          //       this.memberList = res.records;
          //     }
          //
          //   });
          // },1000);

          this.$http.get("maintenance/maintain-plan",{next:true,elevatorId:this.model.id}).then(data => {
            this.contentLoading = false;
            this.maintainPlan = data;
          });

        } else {
          // this.initMap();
        }
      },
      initMap() {
        this.$nextTick(() => {
          this.$refs.map.initMap();
        });
      },


      //获取报告列表
      getReportList() {
        if (this.id) {
          const parmars  = {...this.filter, elevatorId:this.id};
          this.filter = {...this.filter, elevatorId:this.id};
          // this.contentLoading = true;
          this.reportLoading=true;
          this.$http.get("basic/elevator-half-month-operation-report/list",parmars).then(data => {
            this.reportList=data;
            this.reportLoading=false;
          }).catch(()=>{
            this.reportLoading=false;
          });
        } else {
          // this.initMap();
        }
      },

      manufacturingSelect(row) {
        this.model.manufacturingId = row.id;
        this.model.manufacturingName = row.name;
      },

      selectWorker(index, row) {
        let idKey = `wbWorker${index}Id`;
        let nameKey = `wbWorker${index}Name`;
        this.model[idKey] = row.id;
        this.model[nameKey] = row.name;
      },
      clearWorker(index) {
        let idKey = `wbWorker${index}Id`;
        let nameKey = `wbWorker${index}Name`;
        this.model[idKey] = "";
        this.model[nameKey] = "";
      },
      clearDevice() {
        this.model.deviceId = 0;
        this.model.deviceNo = "";
      },
      faultTemplateSelect(row) {
        this.model.faultTemplateId = row.id;
        this.model.faultTemplateName = row.name;
      },
      eventTemplateSelect(row) {
        this.model.eventTemplateId = row.id;
        this.model.eventTemplateName = row.name;
      },
      clearFaultTemplate() {
        this.model.faultTemplateId = 0;
        this.model.faultTemplateName = "";
      },
      clearEventTemplate() {
        this.model.eventTemplateId = 0;
        this.model.eventTemplateName = "";
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            console.log(this.model);
            this.model.floorMappings = this.$refs.elevatorFloor.getFloorMappings();
            console.log(this.model);
            return false;
          // this.$http
          //   .save("elevators", this.model)
          //   .then(() => {
          //     this.submitLoading = false;
          //     this.dialogVisible = false;
          //     this.$emit("save-success");
          //     this.$message.success("保存成功");
          //   })
          //   .catch(() => {
          //     this.submitLoading = false;
          //   });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
      goback() {
        console.log(362);
        this.dialogVisible = false;
        // this.$router.push("/elevator/ElevatorList");
      },
      modalSelect(row) {
        this.$http.post(`elevator/base/brand-model/${this.id}`,{modelId:row.id}).then(()=>{
          this.getModalDetail();
        });
        // this.$set(this.model.model,"manufacturerName", row.manufacturerName);
        // this.$set(this.model.model,"brandName", row.brandName);
        // this.$set(this.model.model,"modelName", row.modelName);
        // this.$set(this.model.model,"categoryDesc", row.categoryDesc);
        // this.$set(this.model.model,"varietyDesc", row.varietyDesc);
        // this.$set(this.model.model,"controlSystemDesc", row.controlSystemDesc);

      },
      getModalDetail() {
        this.$http.get(`elevator/base/${this.id}`).then(data => {
          this.contentLoading = false;
          this.$message.success("修改成功");
          this.model.model = data.model;
          if (!data.model) {
            this.model.model={};
          }

        });
      },
      getDeviceDetail() {
        this.$http.get(`elevator/iot/${this.id}`).then(data => {
          this.contentLoading = false;
          this.device = data;
        });
      },
      getProjectDetail() {
        this.$http.get("project/maintenance-project",{elevatorId:this.id}).then(data => {
          this.contentLoading = false;
          this.maintenanceProject = data;
          this.teamId= data.teamId;
          window.setTimeout(()=>{
            this.$http.get("organization/team/member/page",{teamId:this.teamId}).then(res => {
              if (this.teamId) {
                this.contentLoading = false;
                this.memberList = res.records;
              }else {
                this.memberList=[];
              }

            });
          },100);
        });
      },
      getFactoryDetail() {
        this.$http.get(`elevator/base/${this.id}`).then(data => {
          this.contentLoading = false;
          this.model.factory = data.factory;
          this.$message.success("修改成功");
          if (!data.factory) {
            this.model.factory={};
          }
          this.factoryEdit=false;
        });
      },
      factorySubmit() {
        this.$http.post(`/elevator/base/factory/${this.id}`,this.model.factory).then(()=>{
          this.getFactoryDetail();
        });
      },
      getRegistrationDetail() {
        this.$http.get(`elevator/base/${this.id}`).then(data => {
          this.contentLoading = false;
          this.model.registration = data.registration;
          this.$message.success("修改成功");
          if (!data.registration) {
            this.model.registration={};
          }
          this.registrationEdit=false;
        });
      },
      registrationSubmit() {
        this.$http.post(`/elevator/base/registration/${this.id}`,this.model.registration).then(()=>{
          this.getRegistrationDetail();
        });
      },
      buildingSelect(row) {
        this.$set(this.model.registration,"buildingId", row.id);
        this.$set(this.model.registration,"buildingName", row.name);
        this.$set(this.model.registration,"usingCompanyName", row.usingCompanyName);
        this.$set(this.model.registration,"realEstateName", row.realEstateName);
        this.$set(this.model.registration,"realEstateId", row.realEstateId);
        this.$set(this.model.registration,"realEstateName", row.realEstateName);

      },
      deviceSelect(row) {
        const obj = {...this.device,...row};
        this.$http.post(`elevator/iot/${this.id}`,obj).then(()=>{
          this.getDeviceDetail();
        });
      },
      projectSelect(row) {
        const obj={...this.maintenanceProject,...row};
        this.$http.post(`elevator/maintenance-project/${this.id}`,obj).then(()=>{
          this.getProjectDetail();
        });
      },
      goToBasicFault(row) {
        if (this.os==="new") {
          let obj ={};
          this.menus.forEach(item=>{
            if (item.menuName==="电梯故障字典") {
              obj=item;
            }
          });
          // sessionStorage.setItem("iotCode",row.elevatorIot.iotDeviceName);
          this.$emit("navigator",obj);
          this.$emit("query",{faultCode:row.faultCode});
        }else {
          if (row.faultDictId) {
            this.$refs.faultDetail.open(row.faultDictId);
          }else {
            this.$router.push({path: "/basic/fault",query:{faultCode:row.faultCode}});
          }


        }

      },
      updateFloors() {
        const count = this.aboveGroundFloorCount-this.underGroundFloorCount;
        if (count!==this.model.factory.floors.length) {
          this.model.factory.floors=[];
          for (let i = 0; i < count; i++) {
            if (i+this.underGroundFloorCount<0) {
              this.model.factory.floors.push({
                // elevatorId:this.model.id,
                name:i+1,
                displayName:i+this.underGroundFloorCount,
              });
            }else {
              this.model.factory.floors.push({
                // elevatorId:this.model.id,
                name:i+1,
                displayName:i+this.underGroundFloorCount+1,
              });
            }
          }
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
.table{
  //border: 1px solid black;

}
.tag{
  font-size: 20px;
  margin: 5px 0;
  text-align: center;
  color: black;
  font-weight: bolder;
  border: none;
  background-color: white;
}
.tableTd{
  text-align: center;
  border: 1px solid black;
  width: 100px;
}
.content{
  margin: 5px 0 5px 15px;
}
.table{
  margin-top: 5px;
  margin-left: 15px;
}
</style>
